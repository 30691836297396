// export const backendUrl = "https://staging-api.blacktravelconnects.com";
// export const prodUrl = "https://staging-api.blacktravelconnects.com";
// export const siteUrl = "https://blacktravells-frontend.vercel.app";
export const backendUrl = process.env.REACT_APP_API_URL;
export const prodUrl = process.env.REACT_APP_API_URL;
export const siteUrl = process.env.REACT_APP_FRONTEND_URL;

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const params = new URLSearchParams();
  params.append("token", refreshToken);

  const requestOptions = {
    method: "POST",
    body: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  try {
    const response = await fetch(
      `${backendUrl}/auth/refreshToken`,
      requestOptions
    );
    const data = await response.json();
    await localStorage.setItem("token", data.token);
    return data.token;
  } catch (error) {
    return null;
  }
};

export default refreshAccessToken;
