import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import refreshAccessToken from "./utils/RefreshToken";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers abc
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
class App extends Component {
  render() {
    axios.interceptors.response.use(
      function (successRes) {
        return successRes;
      },
      async function (error) {
        const originalRequest = error.config;

        if (
          localStorage.getItem("refreshToken") &&
          ((error.response.data.data.non_field_error &&
            error.response.data.data.non_field_error[0] === "Invalid Token!") ||
            (error.response.data.data.non_field_error &&
              error.response.data.data.non_field_error[0] ===
                "Unauthorized")) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          // const accessToken = await refreshAccessToken();
          const token = await refreshAccessToken();
          if (token) {
            originalRequest.headers.Authorization = `Bearer ` + token;
            return axios(originalRequest);
          } else {
            localStorage.clear();
            window.location.reload();
            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
